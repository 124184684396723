import React                        from 'react';
import {Pages}                      from "./Pages";
import ReactDOM                     from "react-dom";
import {EntitiesSearchDropList}     from "../components/EntitiesSearchDropList";
import {Entities}                   from "../enums/Entities";

export class Certificates extends Pages{

    initPageHandlers() {

        super.initPageHandlers();
        this.data = window.diaryJsData;

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"поиск сертификата по номеру или по ФИО слушателя"}
                entity={Entities.CERTIFICATES}/>,
            document.getElementById('certificates-search-form-component'),
        );

    }
}
